import React, { Component, useState, useEffect } from 'react';
import MainLayout from '../../components/layout/MainLayout';
import RewardCard from '../../components/Card/RewardCard';
import DvoucherCard from '../../components/Card/DvoucherCard';
import jackpot from '../../assets/main-jackpot.png';
import roundedrectangle from '../../assets/Rounded Rectangle 2582.png';
import layer2589 from '../../assets/fire.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import CountdownTimer from '../../components/CountdownTimer';
import { useNavigate } from 'react-router-dom';
import glow1 from '../../assets/glow1.png';
import glow2 from '../../assets/glow2.png';
import glow3 from '../../assets/glow.png';
import token from '../../assets/token.png';
import arrow from '../../assets/step_arrow.png';
import nft from '../../assets/nft_marketplace.png';
import lottery from '../../assets/lottery_ticket.png';
import bronze from '../../assets/dvoucher1.png';
import silver from '../../assets/dvoucher2.png';
import gold from '../../assets/dvoucher3.png';
import platinum from '../../assets/dvoucher4.png';
import atom1 from '../../assets/atom1.png';
import atom2 from '../../assets/atom2.png';
import atom3 from '../../assets/atom3.png';
import atom4 from '../../assets/atom4.png';
import LotteryCard from '../../components/Card/LotteryCard';
import JoinLotteryPopup from '../../components/Popup/JoinLotteryPopup';
import { useCustomWeb3 } from '../../utils/web3/Web3ManageProvider';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { wagmiConfig, projectId, chains } from '../../utils/config';

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-border-radius-master': '0px',
  },
});

const Main = () => {
  const { account } = useCustomWeb3();
  const modal = useWeb3Modal();
  const [openJoinLotteryPopup, setOpenJoinLotteryPopup] = React.useState(false);
  const [isOpen2, setOpen2] = React.useState(true);
  const [isOpen3, setOpen3] = React.useState(true);
  const [isOpen4, setOpen4] = React.useState(true);
  const [isOpen5, setOpen5] = React.useState(true);
  const [isOpen6, setOpen6] = React.useState(true);

  const [isOpen7, setOpen7] = React.useState(true);
  const navigate = useNavigate();

  const handleJoinLotteryPopup = () => {
    account ? setOpenJoinLotteryPopup(true) : modal.open();
  };

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const end_date = lastDay;

  return (
    <MainLayout>
      <div className="px-5 md:px-56 mt-5 smeTitle01 wow fadeInUp" data-wow-delay="0.1s">
        <h1 className="font-play text-white-main text-9 md:text-1 text-center my-10">welcome to The Quantum Foundation reward program</h1>
        <div className="text-center font-montserrat mt-5">
          <p className="text-white-main text-2 font-normal">dVoucher rewards can be user for</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10 lg:gap-6 xl:gap-16 mt-5">
            <div className="flex flex-col">
              <div className="flex text-[#e3ac59] font-bold items-center">
                <button className="w-[30px] h-[30px] border-2 border-solid border-[#e3ac59] text-center mr-5 rounded-[5px]">1</button>
                <p className="leading-[48px]">Convert into tokens</p>
              </div>
              <div className="flex justify-center my-7">
                <img className="w-[120px] h-[100px] items-center" src={token} alt="token" />
              </div>
              <span className="text-white-2 text-start text-[14px]">
                dVoucher can be exchanged for any Quantum Foundation project tokens with the same $:$ face value
              </span>
            </div>

            <div className="flex flex-col">
              <div className="flex text-[#e3ac59] font-bold items-center">
                <button className="w-[30px] h-[30px] border-2 border-solid border-[#e3ac59] text-center mr-5 rounded-[5px]">2</button>
                <p className="leading-[48px]">Sold on NFT marketplaces</p>
              </div>
              <div className="flex justify-center my-7">
                <img className="w-[120px] h-[100px] items-center" src={nft} alt="nft marketplace" />
              </div>
              <span className="text-white-2 text-start text-[14px]">The dVoucher reward NFT tokens can be traded on NFT marketplaces</span>
            </div>

            <div className="flex flex-col">
              <div className="flex text-[#e3ac59] font-bold items-center">
                <button className="w-[30px] h-[30px] border-2 border-solid border-[#e3ac59] text-center mr-5 rounded-[5px]">3</button>
                <p className="leading-[48px]">Used as a lottery ticket</p>
              </div>
              <div className="flex justify-center my-7">
                <img className="w-[120px] h-[100px] items-center" src={lottery} alt="lottery ticket" />
              </div>
              <span className="text-white-2 text-start text-[14px]">The dVoucher can be sacrificed and burned in exchange for a lottery ticket</span>
            </div>
          </div>
          {isOpen7 && (
            <div className="table md:absolute md:block bottom-56 md:translate-y-1/2 right-0 round-rec rounded-[10px] md:rounded-r-[0] mx-auto">
              <div className="flex items-center p-1">
                <FontAwesomeIcon icon={faCaretRight} className="mx-1 text-white-main cursor-pointer" onClick={() => setOpen7(false)} />
                <img className="fire-img" width="50" src={layer2589} alt="fire" />
                <p className="mx-2 text-6 text-white-main leading-[18px] font-bold font-montserrat">
                  Hurry, investors gain 1,458,586
                  <br />
                  lottery tickets for this month
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="main-bg">
        <div className="px-5 md:px-56">
          <div className="mt-14 ">
            <p className="text-2 leading-[36px] text-white-2 font-montserrat text-center mb-8 md:mb-12">
              Choose how do you want to gain your dVouchers
            </p>
            <div className="grid gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              <div
                onClick={() => {
                  navigate('/rewards/personal-rewards');
                }}
              >
                <RewardCard delay="0.3s" image="reward1" compensation="Up to $250,000 a month" title="Personal rewards" />
              </div>
              <div
              // onClick={() => {
              //   navigate('/rewards/group-rewards');
              // }}
              >
                <RewardCard delay="0.4s" image="reward2" compensation="Up to $200,000 a month" title="Group rewards" />
              </div>
              <div
              // onClick={() => {
              //   navigate('/rewards/competition-rewards');
              // }}
              >
                <RewardCard delay="0.5s" image="reward3" compensation="Up to $250,000 a month" title="Competition" />
              </div>
              <div
              // onClick={() => {
              //   navigate('/rewards/social-engagement');
              // }}
              >
                <RewardCard delay="0.6s" image="reward4" compensation="Up to $250,000 a month" title="Social engagement" />
              </div>
              <div
                onClick={() => {
                  navigate('/rewards/referral-program');
                }}
              >
                <RewardCard delay="0.7s" image="reward5" compensation="Unlimited dVouchers" title="Referral program" />
              </div>
              <div
              // onClick={() => {
              //   navigate('/rewards/staking-rewards');
              // }}
              >
                <RewardCard delay="0.8s" image="reward6" compensation="Unlimited dVouchers" title="Staking reward" />
              </div>
              <div
              // onClick={() => {
              //   navigate('/rewards/trading-rewards');
              // }}
              >
                <RewardCard delay="0.9s" image="reward7" compensation="Unlimited dVouchers" title="Trading rewards" />
              </div>
              <div
              // onClick={() => {
              //   navigate('/rewards/dead-token-recovery');
              // }}
              >
                <RewardCard delay="1s" image="reward8" compensation="Unlimited dVouchers " title="Dead tokens recovery" />
              </div>
            </div>
            <hr className="mt-16 border-white-3" />
          </div>
        </div>
      </div>

      <div className="mt-12 px-5 md:px-56">
        <p className="text-2 leading-[36px] text-white-2 font-montserrat text-center mb-8 md:mb-12">Types of dVouchers</p>
        <div className="grid gap-7 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <DvoucherCard delay="1" image="dvoucher1" compensation="Bronze Value of $1" title="Bronze lottery results" />
          <DvoucherCard delay="2" image="dvoucher2" compensation="Silver Value of $10" title="Silver lottery results" />
          <DvoucherCard delay="3" image="dvoucher3" compensation="Gold Value of $100" title="Gold lottery results" />
          <DvoucherCard delay="4" image="dvoucher4" compensation="Platinum Value of $1000" title="Platinum lottery results" />
        </div>
      </div>
      <div className="mt-16 px-5 md:px-56 wow text-white-main">
        <h3 className="text-9 leading-[42px] font-montserrat text-center ">How does it's works?</h3>
      </div>
      <div className="my-10 wow px-5 md:px-56 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
        <div className="flex text-white-2 font-bold">
          <p className="w-[30px] h-fit py-1 px-2 border-2 border-solid border-white-2 text-center mr-5 rounded-[5px]">1</p>
          <p className="text-5">Select from multiple ways to earn dVouchers</p>
          <img className="ml-10 w-10 h-10 hidden lg:block" src={arrow} alt="next step" />
        </div>

        <div className="flex text-white-2 font-bold ">
          <p className="w-[30px] h-fit py-1 px-2 border-2 border-solid border-white-2 text-center mr-5 rounded-[5px]">2</p>
          <p className="text-5">Gain dVoucher rewards to your wallet</p>
          <img className="ml-10 w-10 h-10 hidden lg:block" src={arrow} alt="next step" />
        </div>

        <div className="flex text-white-2 font-bold ">
          <p className="w-fit h-fit py-1 px-2 border-2 border-solid border-white-2 text-center mr-5 rounded-[5px]">3</p>
          <p className="text-5">Sell your dVouchers on NFT marketplaces, or convert them to tokens or lottery tickets</p>
        </div>
      </div>
      <div className="relative">
        <div className="md:flex justify-center relative mb-5">
          <div className="w-full md:w-auto wow zoomIn flex items-center justify-center my-10" data-wow-delay="0.2s">
            <img className="animate-glow1 w-96" src={glow1} alt="glow1" style={{ zIndex: 0 }} />
            <img className="animate-glow2 absolute w-1/2" src={glow2} alt="glow2" style={{ zIndex: 0 }} />
            <img className="absolute" src={glow3} alt="glow3" style={{ zIndex: 0 }} />
            <img src={jackpot} alt="jackpot" className="absolute" style={{ zIndex: 1 }} />
          </div>
        </div>
      </div>
      <div className="text-center text-white-main font-bold font-montserrat text-2">
        <p>Total dVouchers</p>
        <CountdownTimer handleJoinLotteryPopup={handleJoinLotteryPopup} />
      </div>
      <div className="mt-16 px-5 md:px-56 wow fadeInRight" data-wow-delay="0.1s">
        <div
          className={`accordion-title flex justify-between text-white-main items-center ${isOpen3 ? 'open' : ''}`}
          onClick={() => setOpen3(!isOpen3)}
        >
          <p className="uppercase text-9 leading-[42px] font-play">mega jackpot lottery</p>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        <div className={`accordion-item ${!isOpen3 ? 'collapsed' : 'accordian'}`}>
          <p className="leading-[30px] text-white-2 font-montserrat my-5">
            The Mega Jackpot Lottery restarts monthly and represents the biggest prize among all other lotteries. Each sacrificed dVoucher will earn a
            lottery ticket based on its value. For example, a Bronze dVoucher, equivalent to $1, will convert into 1 lottery ticket, while a Gold
            dVoucher, equal to $100, will convert into 100 lottery tickets.
          </p>
        </div>
      </div>
      <div className="mt-16 px-5 md:px-56 wow fadeInRight" data-wow-delay="0.1s">
        <div
          className={`accordion-title flex justify-between text-white-main items-center ${isOpen4 ? 'open' : ''}`}
          onClick={() => setOpen4(!isOpen4)}
        >
          <p className="uppercase text-9 leading-[42px] font-play">Lottery Payout Calculation per class</p>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        <div
          className={`accordion-item text-8 flex flex-col leading-[30px] text-white-2 font-montserrat my-10 ${!isOpen4 ? 'collapsed' : 'accordian'}`}
        >
          <p>
            dVouchers can be utilized for any lottery, with each lottery featuring its unique prize. A single dVoucher converts into a lottery ticket,
            generating a unique six-digit ticket number. If this number matches the raffle number exactly, the winners will split 24% of the prize
            between them. In the case that the ticket matches fewer than six numbers, a smaller prize is split among the group that shares the same
            amount of matches. There are a total of six groups with six allocations of prizes, as follows:
          </p>
          <span className="mt-10">1 digit matches: 1%</span>
          <span>2 digits match: 10%</span>
          <span>3 digits match: 15%</span>
          <span>4 digits match: 20%</span>
          <span>5 digits match: 30%</span>
          <span>6 digits match: 24%</span>
        </div>
      </div>
      <div className="mt-16 mb-32 px-5 md:px-56 wow fadeInRight" data-wow-delay="0.1s">
        <div
          className={`accordion-title flex justify-between text-white-main items-center ${isOpen5 ? 'open' : ''}`}
          onClick={() => setOpen5(!isOpen5)}
        >
          <p className="uppercase text-9 leading-[42px] font-play">join the lottery</p>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        <div className={`accordion-item ${!isOpen5 ? 'collapsed' : 'accordian'} mt-7`}>
          <div className="flex-row lg:flex text-white-2 py-3 border-solid border-y border-white-6 mt-3 justify-center items-center xs:hidden">
            <div className="w-1/5"></div>
            <div className="w-1/5"></div>
            <div className="w-1/5">The upcoming prize</div>
            <div className="w-1/5">Lottery start in</div>
            <div className="w-1/5 text-center">Join the lotteries</div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-5">
            <LotteryCard image1={platinum} image2={atom4} name={'Platinum'} endDate={end_date} handleJoinLotteryPopup={handleJoinLotteryPopup} />
            <LotteryCard image1={gold} image2={atom3} name={'Gold'} endDate={end_date} handleJoinLotteryPopup={handleJoinLotteryPopup} />
            <LotteryCard image1={silver} image2={atom2} name={'Silver'} endDate={end_date} handleJoinLotteryPopup={handleJoinLotteryPopup} />
            <LotteryCard image1={bronze} image2={atom1} name={'Bronze'} endDate={end_date} handleJoinLotteryPopup={handleJoinLotteryPopup} />
          </div>
        </div>
      </div>
      <JoinLotteryPopup openPopup={openJoinLotteryPopup} closePopup={() => setOpenJoinLotteryPopup(false)} />
    </MainLayout>
  );
};

export default Main;
