import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';

const GroupListOption = ({ option, checked }) => {
    const label = option.title.split(' ', 2).map(w => w[0].toUpperCase()).join('');

    return <div className='flex items-center justify-between w-full px-4 my-2'>
        <div className='flex items-center'>
            <div className='relative w-[32px] h-[32px] overflow-hidden rounded-3xl mr-4 bg-white-2 flex items-center justify-center'>
                {label}
                {option.photo && <img className='absolute top-0 left-0 right-0 bottom-0' src={`${process.env.REACT_APP_CUSTOM_TELEGRAM_APP_URL}/api/nfts/telegram/chat_photo/${option.photo.small_file_id}`} alt={option.title} width={32} height={32} />}
            </div>
            <Typography color='textPrimary'>{option.title}</Typography>
        </div>
        <Checkbox color='primary' checked={checked} />
    </div>
};

export default GroupListOption;