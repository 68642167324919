import React, { createContext, useCallback, useState, useMemo, useContext, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import useEagerConnect from './useEagerConnect';
import useInactiveListener from './useInactiveListener';
import JsonStorage, { WALLET_ADDRESS, WALLET_CONNECTOR } from '../json.storage';
import { metamask, coinbaseWallet, walletConnect, binanceConnect } from '../connectors';
import { useAccount, Connector, useConnect, useSwitchChain } from 'wagmi';

export const connectors = {
  BinanceWallet: binanceConnect,
  WalletConnect: walletConnect,
  MetaMask: metamask,
  Coinbase: coinbaseWallet,
};

export const Web3ManageContext = createContext({
  account: undefined,
  connectorName: undefined,
});

export default function Web3ManageProvider({ children }) {
  const { address, connector, chain } = useAccount();
  const { chains, switchChain } = useSwitchChain();
  const connectorName = connector?.name || null;

  useEffect(() => {
    if (address && chain.id !== 97) {
      switchChain({ chainId: 97 });
    }
  }, [address, chain]);

  const checkedConnect = useEagerConnect(connectorName);
  useInactiveListener(checkedConnect, connectorName);

  const value = useMemo(() => {
    JsonStorage.set(WALLET_ADDRESS, address);
    return {
      account: address,
      connectorName,
      chainId: chain?.id,
    };
  }, [address, connector, chain]);

  if (!checkedConnect) return null;

  return <Web3ManageContext.Provider value={value}>{children}</Web3ManageContext.Provider>;
}

export const useCustomWeb3 = () => {
  return useContext(Web3ManageContext);
};
