import React, { useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import PrimaryButton from '../../../Buttons/PrimaryButton';
import InvitationConfirmLogo from '../../Assets/invitation-confirm.png';
import { useCustomWeb3 } from '../../../../utils/web3/Web3ManageProvider';

const ConfirmWalletConnect = ({ onNext, groupCount, friendsCount, t }) => {
  const { account } = useCustomWeb3();
  const openConnectModal = useCallback(() => {
    document.getElementById('wallet-connect-modal-button').click();
  }, []);

  useEffect(() => {
    if (account) {
      onNext?.();
    }
  }, [account]);

  return (
    <>
      <div className="auth-caption">
        <Typography variant="body1" color="textPrimary" className="max-w-[594px] text-[21px] font-bold text-center mb-[50px] mt-[20px]">
          An invite for
          <Typography component="span" color="primary" className="text-[21px] font-bold">
            &nbsp;{groupCount}&nbsp;
          </Typography>
          groups has been sent to
          <Typography component="span" color="primary" className="text-[21px] font-bold">
            &nbsp;{friendsCount}&nbsp;
          </Typography>
          of your friends! If they invite their friends, your total potential reward will be
          <Typography component="span" color="primary" className="text-[21px] font-bold">
            &nbsp;${friendsCount * groupCount}&nbsp;
          </Typography>
        </Typography>
        <img src={InvitationConfirmLogo} alt="telegram logo" width={140} height={140} />
      </div>
      <div className="flex justify-center flex-col pt-[50px]">
        <PrimaryButton className="auth-button-full text-8 uppercase mt-5 btn-green flex items-center justify-center" onClick={openConnectModal}>
          {t('LINK YOUR WALLET TO CLAIM REWARDS')}
        </PrimaryButton>
      </div>
    </>
  );
};

export default withTranslation()(ConfirmWalletConnect);
