import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import InvitationConfirmLogo from '../../Assets/invitation-confirm.png';
import RewardsIcon from '../../Assets/rewards-icon.png';
import PrimaryButton from '../../../Buttons/PrimaryButton';

const LinkedWalletSuccessfully = ({ onNext, t }) => {
  const handleNext = useCallback(() => {
    onNext?.();
  }, []);

  return (
    <>
      <div className="auth-caption">
        <Typography variant="body1" color="primary" className="max-w-[594px] reward-caption-text mb-[50px]">
          Wallet linked successfully!
        </Typography>
        <img src={InvitationConfirmLogo} alt="telegram logo" width={140} height={140} />
      </div>
      <div className="flex justify-center flex-col pt-[50px]">
        <PrimaryButton className="auth-button-full text-8 uppercase mt-5 btn-green flex items-center justify-center h-[85px]" onClick={handleNext}>
          {t('CHECK OUT ADDITIONAL REWARDS')} <img src={RewardsIcon} width={35} height={35} className="ml-5" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default withTranslation()(LinkedWalletSuccessfully);
