import React from 'react';
import reward from '../../assets/reward.png';

const TopBanner = () => {
  return (
    <div className="sticky overflow-y-hidden top-0 text-[16px] z-30 hover:cursor-pointer flex font-montserrat font-bold justify-center h-[40px] bg-yellow-main py-2 items-center text-black text-center">
      <div className="w-4/5 flex items-center justify-center">
        <a className="transition-all hover:drop-shadow-[0px_0px_35px_rgba(0,0,0)] flex relative">
          <a href="#" className="absolute inset-1/2 shadow-[0px_0px_1000px_200px_rgba(0,0,0,.2)]"></a>
          Refer & win up to a <span className="mx-1 text-white-main">$1000</span> reward
          <img className="ml-5 w-5 xs:hidden md:block" src={reward} alt="reward" />
        </a>
      </div>
    </div>
  );
};

export default TopBanner;
