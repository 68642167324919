

import React from 'react';
import './HeaderProgress.css';

class HeaderProgress extends React.Component {
    render() {
        return (
            <span className='h-2 mt-[-35px] block'>
                <span className='header-progress text-white-4'>.</span>
                <span className='header-progress text-white-4'>.</span>
                <span className='header-progress text-white-4'>.</span>
            </span>
        );
    }
}

export default HeaderProgress;
