import React, { useState, useEffect } from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shortAddress, claimRewards, getDvoucherPending, getDvoucherBalance } from '../../utils/utils';
import { registerUserWallet, getRewardPoints } from '../../api/';
import { useCustomWeb3 } from '../../utils/web3/Web3ManageProvider';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { wagmiConfig, projectId, chains } from '../../utils/config';
import { useDisconnect } from 'wagmi';

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    '--w3m-border-radius-master': '0px',
  },
});

const WalletConnect = () => {
  const { account } = useCustomWeb3();
  const { disconnect } = useDisconnect();
  const [dvoucherPending, setDvoucherPending] = useState();
  const [dvoucherBalance, setDvoucherBalance] = useState();
  const modal = useWeb3Modal();

  useEffect(() => {
    if (account) {
      registerUserWallet(account).then(async () => {
        getRewardPoints(account).then(async response => {
          const pending = response.data.result;
          pending ? setDvoucherPending(pending) : setDvoucherPending(0);
          const res = await getDvoucherBalance(account);
          let balance = res.mega * 10000 + res.platinum * 1000 + res.gold * 100 + res.silver * 10 + res.bronze;
          balance ? setDvoucherBalance(balance) : setDvoucherBalance(0);
          localStorage.setItem('DVoucherBalance', JSON.stringify(res));
        });
      });
    } else {
      localStorage.setItem('DVoucherBalance', JSON.stringify({ mega: 0, platinum: 0, gold: 0, silver: 0, bronze: 0 }));
    }
  }, [account]);

  const handleClaim = async () => {
    const result = await claimRewards(dvoucherPending);
    if (result) {
      setDvoucherBalance(dvoucherPending + dvoucherBalance);
      setDvoucherPending(0);
    }
  };

  const handleDisconnectWallet = () => {
    localStorage.setItem('DVoucherBalance', JSON.stringify({ mega: 0, platinum: 0, gold: 0, silver: 0, bronze: 0 }));
    disconnect();
  };

  return (
    <div>
      {account ? (
        <div className="flex items-center">
          <p className="text-4 text-white-2 font-montserrat">
            Total Points:
            <span className="pl-1">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
            <span className="text-white-main font-bold pl-2">{dvoucherPending || 0}</span>
          </p>
          <button
            className="btn-gold w-[120px] h-[35px] rounded-[5px] border-2 border-solid border-yellow-main text-yellow-main text-4 font-montserrat font-bold ml-4"
            onClick={handleClaim}
          >
            CLAIM
          </button>
          <div className="text-white-8 text-4 font-montserrat mx-4">|</div>
          <p className="text-4 text-white-2 font-montserrat">
            dVoucher balance: <span className="font-bold text-yellow-main">{dvoucherBalance || 0}</span>
          </p>
          <div className="text-white-8 text-4 font-montserrat mx-4">|</div>
          <button className="font-bold text-white-main text-4 font-montserrat">0x{shortAddress(account)}</button>

          <button
            className="header-btn w-[120px] h-[35px] rounded-[5px] ml-4 bg-yellow-main border-2 border-solid border-white-2 bg-opacity-0 text-white-2 font-bold text-4 font-montserrat btn-gold"
            onClick={() => handleDisconnectWallet()}
          >
            Disconnect
          </button>
        </div>
      ) : (
        <button
          id="wallet-connect-modal-button"
          className="header-btn w-[120px] h-[35px] rounded-[5px] bg-yellow-main border-2 border-solid border-white-2 bg-opacity-0 text-white-2 font-bold text-4 font-montserrat btn-gold"
          onClick={() => modal.open()}
        >
          Connect
        </button>
      )}
    </div>
  );
};

export default WalletConnect;
