import React from 'react';
import { ReactComponent as Logo } from '../telegram-logo.svg';

const TelegramLogo = () => {
  return (
    <span className="telegram-logo">
      <Logo />
    </span>
  );
};

export default TelegramLogo;
