import React from 'react';
import AppBar from '../AppBar/AppBar';
import Footer from '../Footer/Footer';
import reward from '../../assets/reward.png';

const MainLayout = ({ children }) => {
  return (
    <>
      <div className="background">
        <AppBar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
