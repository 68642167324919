import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useConnect, useAccount } from 'wagmi';

const useEagerConnect = connectorName => {
  const [checkedConnection, setCheckedConnection] = useState(false);
  const { isConnected } = useAccount();
  const { connect, connectors } = useConnect();

  useEffect(() => {
    if (connectorName) {
      const connector = connectors.find(con => con.name === connectorName);
      if (connector.isAuthorized) {
        connector.isAuthorized().then(isAuthorized => {
          if (isAuthorized) {
            connect(connector);
            setCheckedConnection(true);
          } else {
            if (isMobile && window.ethereum) {
              connect(connector);
              setCheckedConnection(true);
            } else {
              setCheckedConnection(true);
            }
          }
        });
      } else {
        setCheckedConnection(true);
      }
    } else {
      setCheckedConnection(true);
    }
  }, [connect, connectorName]);

  useEffect(() => {
    if (isConnected) {
      setCheckedConnection(true);
    }
  }, [isConnected]);

  return checkedConnection;
};

export default useEagerConnect;
