import React, { useCallback, useEffect, useRef, useState } from 'react';

import SelectGroups from './SelectGroups';
import SelectFriends from './SelectFriends';
import ConfirmInvitations from './ConfirmInvitations';
import TdLibController from '../../Controllers/TdLibController';
import telegramService from '../../../../core/services/telegram.service';
import { toast } from 'react-toastify';
import { getChatTitle } from '../../Utils/Chat';
import { sleep } from '../../../../utils/utils';
import { getEntities } from '../../Utils/Message';
import { ArrowBack, MoreVert } from '@material-ui/icons';
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Typography } from '@material-ui/core';
import ChatStore from '../../Stores/ChatStore';
import UserStore from '../../Stores/UserStore';
import { userSentTgInvitations } from '../../../../api';
import { useCustomWeb3 } from '../../../../utils/web3/Web3ManageProvider';
import LogOut from '../../Assets/Icons/LogOut';
import LinkedWalletSuccessfully from './LinkedWalletSuccessfully';
import ConfirmWalletConnect from './ConfirmWalletConnet';
import AuthorizationStore from '../../Stores/AuthorizationStore';
import { useNavigate } from 'react-router-dom';

const InvitationPage = ({ authorizationState: state }) => {
  const navigate = useNavigate();
  const { account } = useCustomWeb3();
  const [step, setStep] = useState(0);
  const [groups, setGroups] = useState([]);
  const [friends, setFriends] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const invitationInfoRef = useRef([]);

  const gotoFriendsSelection = useCallback(groups => {
    setGroups(groups);
    setStep(1);
  }, []);

  const sendInvitation = useCallback(
    async friends => {
      setFriends(friends);
      invitationInfoRef.current = [];

      const userIds = friends.map(({ id }) => id);
      let userNames = userIds.map(id => getChatTitle(id));
      let sentGroups = [];
      for (let i = 0, c = groups.length; i < c; i++) {
        let group = groups[i];
        try {
          const {
            data: {
              result: { userIds: memberIds },
            },
          } = await telegramService.checkChatMembers(group.id, userIds);
          const nonMemberIds = userIds.filter(id => !memberIds.includes(id));
          if (nonMemberIds.length === 0) {
            toast(`They are already members in ${group.title}.`, { type: 'warning' });
            continue;
          }
          userNames = nonMemberIds.map(id => getChatTitle(id));
          if (i === 0) {
            await sendNotification(groups, nonMemberIds);
          }
          const result = await TdLibController.send({
            '@type': 'addChatMembers',
            chat_id: group.id,
            user_ids: nonMemberIds,
          });
          console.log(result);
          if (result['@type'] == 'ok') {
            if (account) await userSentTgInvitations(account, UserStore.getMyId(), group.id, nonMemberIds);
            else invitationInfoRef.current.push({ group_id: group.id, userIds: nonMemberIds });
            toast(`Successfully added ${userNames.join(', ')} to ${group.title}`, { type: 'success' });
            sentGroups.push(group);
          } else {
            throw new Error('Something went wrong!');
          }
        } catch (error) {
          if (error.message === 'USER_KICKED') {
            toast(`User has been kicked for ${group.title}`, { type: 'error' });
          } else {
            console.log(error.message);
            toast(
              `Failed to add users(${userNames.join(', ')}) for ${group.title}. \n Users(user) have(has) been blocked adding them(himself) to groups.`,
              { type: 'error' },
            );
          }
        } finally {
          await sleep(Math.ceil(Math.random() * 1000));
        }
      }
      if (sentGroups.length > 0) {
        setGroups(sentGroups);
        setStep(2);
      }
    },
    [groups, account],
  );

  const sendNotification = async (groupInfo, userIds) => {
    for (let i = 0, c = userIds.length; i < c; i++) {
      let chatId = userIds[i];
      const href = location.href.search(/localhost/) > -1 ? process.env.REACT_APP_REWARD_FOUNDATION_URL : location.href;
      let groupInfoMsg = '';

      groupInfo.map(group => {
        groupInfoMsg += '@' + group.username + ',';
      });
      groupInfoMsg = groupInfoMsg.slice(0, -1);

      const msg = `Found an awesome reward program we can both benefit from up to $1,000 👈, I've invited you to the @${groupInfoMsg}. so you can go to [<a href='${href}'>this site</a>] to start getting rewarded by inviting your friends  💰💰💰`;

      let { text, entities } = getEntities(msg);

      let chat = ChatStore.get(chatId);

      if (!chat) {
        chat = await TdLibController.send({
          '@type': 'createPrivateChat',
          user_id: chatId,
          force: true,
        });
        chatId = chat.id;
      }
      const result = await TdLibController.send({
        '@type': 'sendMessage',
        chat_id: chatId,
        input_message_content: {
          '@type': 'inputMessageText',
          text: {
            '@type': 'formattedText',
            text,
            entities,
          },
          disable_web_page_preview: false,
          clear_draft: true,
        },
      });

      TdLibController.send({
        '@type': 'viewMessages',
        chat_id: chatId,
        message_ids: [result.id],
      });
    }
  };

  const LoggingOutScreen = () => {
    return (
      <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center z-10 bg-black">
        <Typography color="textSecondary" align="center" className="text-[25px]">
          Logging Out ...
          <br />
          Please wait for a second
        </Typography>
      </div>
    );
  };
  const gotoMainPage = useCallback(() => {
    navigate('/');
  }, []);

  const handleWalletConnect = useCallback(async () => {
    const length = invitationInfoRef.current.length;
    for (let i = 0; i < length; i++) {
      const { group_id, userIds } = invitationInfoRef.current[i];
      await userSentTgInvitations(account, UserStore.getMyId(), group_id, userIds).then();
    }
    invitationInfoRef.current = [];
    setStep(3);
  }, [account]);

  const handleBack = useCallback(() => {
    setStep(prev => {
      let step = prev - 1;
      if (step < 0) return 0;
      return step;
    });
  }, []);

  const openMenu = useCallback(event => setMenuAnchor(event.currentTarget), []);

  const handleClose = useCallback(() => setMenuAnchor(null), []);

  const handleLogout = useCallback(() => {
    handleClose();
    TdLibController.logOut();
  }, []);

  useEffect(() => {
    if (state && state['@type'] === 'authorizationStateLoggingOut') {
      let timer = setTimeout(() => {
        AuthorizationStore.save({ '@type': 'authorizationStateWaitPhoneNumber' });
        AuthorizationStore.load();
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [state]);

  return (
    <div className="authorization-form">
      <div className="authorization-form-content pt-[52px] pb-[44px] px-[34px] relative">
        {step !== 0 && step !== 3 && (
          <div className="absolute top-[15px] left-[34px]">
            <IconButton title="back" onClick={handleBack}>
              <ArrowBack />
            </IconButton>
          </div>
        )}

        <div className="absolute top-[15px] right-[34px]">
          <IconButton title="back" onClick={openMenu}>
            <MoreVert />
          </IconButton>
          <Menu
            id="main-menu"
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleClose}
            getContentAnchorEl={null}
            disableAutoFocusItem
            disableRestoreFocus={true}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogOut />
              </ListItemIcon>
              <ListItemText primary={'Log Out'} />
            </MenuItem>
          </Menu>
        </div>
        {step === 0 && <SelectGroups onNext={gotoFriendsSelection} />}
        {step === 1 && <SelectFriends onNext={sendInvitation} groupCount={groups.length} />}
        {invitationInfoRef.current.length === 0 && step === 2 && (
          <ConfirmInvitations groupCount={groups.length} friendsCount={friends.length} onNext={gotoMainPage} />
        )}
        {invitationInfoRef.current.length && step === 2 && (
          <ConfirmWalletConnect groupCount={groups.length} friendsCount={friends.length} onNext={handleWalletConnect} />
        )}
        {account && step === 3 && <LinkedWalletSuccessfully onNext={gotoMainPage} />}
        {state && state['@type'] === 'authorizationStateLoggingOut' && <LoggingOutScreen />}
      </div>
    </div>
  );
};

export default InvitationPage;
