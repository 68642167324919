import React from 'react';
import AuthErrorDialog from './AuthErrorDialog';
import Caption from './Caption';
import Code from './Code';
import Phone from './Phone';
import { loadData } from '../../Utils/Phone';
import AppStore from '../../Stores/ApplicationStore';
import AuthStore from '../../Stores/AuthorizationStore';
import './AuthForm.css';
import Password from './Password';

class AuthForm extends React.Component {
  state = {
    data: AuthStore.data,
  };

  componentDidMount() {
    setTimeout(this.loadContent, 100);
  }

  loadContent = async () => {
    const { data } = this.state;
    if (data) return;

    try {
      AuthStore.data = await loadData();

      this.setState({ data: AuthStore.data });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { authorizationState: state, onChangePhone, onRequestQRCode } = this.props;
    const { data } = this.state;
    const { defaultPhone } = AppStore;

    let control = null;
    switch (state['@type']) {
      case 'authorizationStateWaitPhoneNumber':
      case 'authorizationStateWaitRegistration':
      case 'authorizationStateWaitEncryptionKey':
      case 'authorizationStateWaitTdlibParameters':
      case 'authorizationStateWaitTdlib': {
        control = <Phone defaultPhone={defaultPhone} data={data} onRequestQRCode={onRequestQRCode} />;
        break;
      }
      case 'authorizationStateWaitCode': {
        const { terms_of_service, code_info } = state;

        control = <Code termsOfService={terms_of_service} codeInfo={code_info} onChangePhone={onChangePhone} data={data} />;
        break;
      }
      case 'authorizationStateWaitPassword': {
        control = <Password />;
        break;
      }
      default:
        break;
    }

    return (
      <div className="authorization-form" onLoad={this.handleLoad}>
        <div className="authorization-form-content pt-[52px] pb-[44px] px-[34px] relative">
          <Caption state={state} />
          {control}
        </div>
        <AuthErrorDialog />
      </div>
    );
  }
}
export default AuthForm;
