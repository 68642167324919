import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import TelegramLogo from '../../Assets/Icons/TelegramLogo';
import ChatStore from '../../Stores/ChatStore';
import TdLibController from '../../Controllers/TdLibController';
import { loadChatsContent, loadUsersContent } from '../../Utils/File';
import FileStore from '../../Stores/FileStore';
import UserStore from '../../Stores/UserStore';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import FriendListOption from './components/FriendListOption';
import { isBotChat, isPrivateChat } from '../../Utils/Chat';
import { getUserFullName, isBotUser, isDeletedUser } from '../../Utils/User';
import LStore from '../../Stores/LocalizationStore';

const SelectFriends = ({ onNext, t, groupCount }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedChats, setSelectedChats] = useState([]);

  const handleNext = useCallback(() => {
    onNext?.(selectedChats);
  }, [selectedChats]);

  const handleFilterOptions = useCallback((options, { inputValue }) => {
    if (!options) return;

    return options.filter(({ title }) => title.toLowerCase().includes(inputValue.toLowerCase()));
  }, []);

  const handleFriendsChange = useCallback((event, values) => {
    setSelectedChats(values);
  }, []);

  const toggleSelectAll = useCallback(() => {
    if (selectedChats.length === users.length) {
      setSelectedChats([]);
    } else {
      setSelectedChats([...users]);
    }
  }, [users, selectedChats.length]);

  const loadChatContents = useCallback(chatIds => {
    const store = FileStore.getStore();
    loadChatsContent(store, chatIds);
  }, []);

  const loadContacts = useCallback(async () => {
    let contacts = await TdLibController.send({
      '@type': 'getContacts',
      limit: 300,
    });
    contacts.user_ids = contacts.user_ids.sort((x, y) => getUserFullName(x).localeCompare(getUserFullName(y)));

    const store = FileStore.getStore();
    loadUsersContent(store, contacts.user_ids);
    let users = {};
    contacts.user_ids
      .filter(userId => !isBotUser(userId) && isDeletedUser(userId))
      .map(id => {
        users[id] = UserStore.get(id);
      });
    return users;
  }, []);

  const loadChats = useCallback(async type => {
    return TdLibController.send({
      '@type': 'getChats',
      chat_list: {
        '@type': type,
      },
      limit: 300,
    }).then(({ chat_ids }) => {
      const users = {};
      const user_ids = chat_ids
        .map(chatId => ChatStore.get(chatId))
        .filter(chat => !isBotChat(chat.id) && isPrivateChat(chat.id) && chat.type.user_id && !isDeletedUser(chat.type.user_id))
        .map(chat => chat.type.user_id);

      const store = FileStore.getStore();
      loadUsersContent(store, user_ids);

      user_ids.forEach(user_id => {
        users[user_id] = UserStore.get(user_id);
      });
      return users;
    });
  }, []);

  useEffect(() => {
    const chatIds = users.map(p => p.id);
    setTimeout(() => {
      loadChatContents(chatIds);
    }, 1000);
  }, [users]);

  useEffect(() => {
    Promise.all([loadContacts(), loadChats('chatListMain'), loadChats('chatListArchive')])
      .then(([contacts, mainUsers = {}, archiveUsers = {}]) => {
        let users = [];
        Object.values(Object.assign(contacts, mainUsers, archiveUsers)).forEach(user => {
          user.title = getTitle(user);
          if (user.title !== 'Telegram') users.push(user);
        });
        setUsers(users);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      setSelectedChats(users);
    }
  }, [users]);

  const nextable = !loading && selectedChats.length > 0;

  return (
    <>
      <div className="auth-caption">
        <Typography variant="body1" color="textPrimary" className="reward-caption-text max-w-[453px] mb-4">
          Get rewards by inviting your friends to our group
        </Typography>
        <TelegramLogo />
      </div>
      <div className="flex justify-center flex-col">
        <div className="text-center mt-7">
          <Typography variant="body1" color="textPrimary" component="span" className="reward-caption-text max-w-[453px] mb-4">
            <Typography component="span" color="primary" className="reward-caption-text">
              &nbsp;{users.length}&nbsp;
            </Typography>
            friends have been found!
          </Typography>
        </div>
        <div className="text-center mb-7 mt-2">
          <Typography variant="body1" className="auth-subtitle" component="span">
            Your total potential reward is
          </Typography>
          <Typography component="span" color="primary">
            &nbsp;${users.length * groupCount}&nbsp;
          </Typography>
          <Typography variant="body1" component="span" className="text-gray-500">
            (${users.length} x {groupCount} groups)
          </Typography>
        </div>
        <Autocomplete
          debug={false}
          id="country-select"
          multiple
          noOptionsText={t('NoResult')}
          options={users || []}
          disabled={loading}
          autoHighlight
          disableCloseOnSelect
          getOptionLabel={option => (option ? option.title || '' : [])}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={option => <FriendListOption option={option} checked={!!selectedChats.find(({ id }) => option.id === id)} />}
          renderInput={params => (
            <TextField
              classes={{ root: 'auth-input remove-selected' }}
              {...params}
              placeholder="Search friends"
              label={t('Friend list')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {selectedChats.length ? <Typography className="text-white-4 pl-1">{selectedChats.length} friends selected</Typography> : ''}
                    <Link className="absolute top-[-26px] right-0" component="button" color="primary" variant="body2" onClick={toggleSelectAll}>
                      {users.length && selectedChats.length === users.length ? 'Unselect All' : 'Select All'}
                    </Link>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          filterOptions={handleFilterOptions}
          value={selectedChats}
          onChange={handleFriendsChange}
        />
        <div className="text-center">
          <PrimaryButton className={`${!nextable ? 'btn-disabled' : 'btn-gold'} auth-button-full text-8 uppercase mt-5`} onClick={handleNext}>
            {t('CLAIM YOUR REWARD')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

function getTitle(user) {
  if (!user.first_name && !user.last_name) return LStore.i18n.t('HiddenName');
  return user.first_name + (user.last_name ? ' ' + user.last_name : '');
}

export default withTranslation()(SelectFriends);
