import { ethers } from 'ethers';
import DVoucherNFT from '../contracts/DVoucherNFT.json';
import DVoucherMinter from '../contracts/DVoucherMinter.json';
import DVoucherDistributor from '../contracts/DVoucherDistributor.json';

const bigNumberToInt = num => {
  return parseInt(num._hex, 16);
};

export const shortAddress = add => {
  const sortAdd = `${add.slice(2, 5)}....${add.slice(add.length - 4)}`;
  return sortAdd;
};

export const getDvoucherPending = async address => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const abi = DVoucherDistributor.abi;
  const distoributor = new ethers.Contract(process.env.REACT_APP_DVOUCHER_DISTRIBUTOR_ADDRESS, abi, signer);
  return distoributor.functions.creditAmount(address).then(res => {
    return bigNumberToInt(res[0]);
  });
};

export const getDvoucherBalance = async address => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const abi = DVoucherNFT.abi;
    const contract = new ethers.Contract(process.env.REACT_APP_DVOUCHER_ADDRESS, abi, signer);
    return Promise.all([0, 1, 2, 3].map(async nftId => contract.functions.balanceOf(address, nftId)))
      .then(([bronze, silver, gold, platinum]) => {
        return {
          platinum: bigNumberToInt(platinum[0]) || 0,
          gold: bigNumberToInt(gold[0]) || 0,
          silver: bigNumberToInt(silver[0]) || 0,
          bronze: bigNumberToInt(bronze[0]) || 0,
        };
      })
      .catch(() => Promise.resolve(0));
  } catch (error) {
    throw Error(`getDvoucherBalance error: ${error}`);
  }
};

// export const getNominalCount = async (nominal) => {
//   let balance
//   const abi = DVoucherNFT.abi
//   const dvoucher = new ethers.Contract(
//     process.env.REACT_APP_DVOUCHER_ADDRESS,
//     abi
//   );
//   dvoucher.functions.nominalCount(nominal).then((res) => {
//     if (res.status != 200) return;
//     balance = res.data
//   })
//   return balance
// }

export const claimRewards = async dvoucherPending => {
  if (dvoucherPending > 0) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const abi = DVoucherDistributor.abi;
    const distoributor = new ethers.Contract(process.env.REACT_APP_DVOUCHER_DISTRIBUTOR_ADDRESS, abi, signer);
    return distoributor.functions.claim().then(res => {
      console.log('Claim : ', res);
      alert(dvoucherPending + ' DVouchers was claimed successfully');
      return true;
    });
  } else {
    alert('Pending point is zero!');
  }
};

export async function sleep(millisecs) {
  return new Promise(resolve => {
    setTimeout(resolve, millisecs);
  });
}
