import React, { useState, useEffect } from 'react';
import platinum from '../../assets/platinum.png';
import silver from '../../assets/silver.png';
import bronze from '../../assets/bronze.png';
import gold from '../../assets/gold.png';
import mega from '../../assets/megalottery.png';
import JoinLotteryCard from '../Card/JoinLotteryCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const JoinLotteryPopup = props => {
  const { openPopup, closePopup } = props;

  const dVoucherBalance = JSON.parse(localStorage.getItem('DVoucherBalance'));

  const handlelosePopUp = e => {
    if (e.target.id === 'ModelContainer') {
      closePopup();
    }
  };

  if (openPopup !== true) return null;

  return (
    <div
      id="ModelContainer"
      onClick={handlelosePopUp}
      className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-70 backdrop-blur-sm z-50"
    >
      <div className="p-2 bg-white w-full h-full lg:w-4/5 shadow-inner border-e-emerald-600 rounded-lg py-5 ">
        <div className="w-full p-3 justify-center items-center text-center flex flex-col">
          <div className="w-full justify-end items-end text-end">
            <FontAwesomeIcon icon={faXmark} className="text-1 text-white-main" onClick={() => closePopup()} />
          </div>
          <span className="font-play text-white-main pb-3 text-center text-1 uppercase">join the lotteries</span>
          <span className="font-montserrat text-white-2 text-center text-8">
            Sacrificed your dVouchers to gain lottery tickets that will reward you with more dVouchers
          </span>
          <div className="flex flex-col w-full mt-5 text-white-2">
            <div className="text-[14px] flex-row hidden md:flex text-white-2 py-3 mb-3 border-solid border-y border-white-6 mt-3 text-start items-center px-7 lg:px-0">
              <div className="w-1/6"></div>
              <div className="w-1/6">Wining Prize</div>
              <div className="w-1/6 px-1">Your dVoucher lottery tickets</div>
              <div className="w-1/2">How many dVoucher to use?</div>
              <div className="w-1/3 text-center">Join the lotteries</div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 md:grid-rows-5 gap-4 max-h-[425px] overflow-y-auto overflow-x-hidden">
              <JoinLotteryCard image={mega} name={'Mega'} tickets={dVoucherBalance.mega} />
              <JoinLotteryCard image={platinum} name={'Platinum'} tickets={dVoucherBalance.platinum} />
              <JoinLotteryCard image={gold} name={'Gold'} tickets={dVoucherBalance.gold} />
              <JoinLotteryCard image={silver} name={'Silver'} tickets={dVoucherBalance.silver} />
              <JoinLotteryCard image={bronze} name={'Bronze'} tickets={dVoucherBalance.bronze} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinLotteryPopup;
