import AuthStore from '../Stores/AuthorizationStore';

export function cleanProgressStatus(status) {
  if (!status) return status;

  return status.replace('...', '').replace('…', '');
}

export function isConnecting(state) {
  if (!state) return false;

  switch (state['@type']) {
    case 'connectionStateConnecting': {
      return true;
    }
    case 'connectionStateConnectingToProxy': {
      return true;
    }
    case 'connectionStateReady': {
      return false;
    }
    case 'connectionStateUpdating': {
      return false;
    }
    case 'connectionStateWaitingForNetwork': {
      return false;
    }
  }

  return false;
}

export function isPhoneWithOptionCode(phone, option) {
  if (!phone) return false;
  if (!option) return false;

  phone = clearPhone(phone);
  const code = clearPhone(option.phone);

  return phone.startsWith(code);
}

export function getCountryFromPhone(phone, data) {
  if (!data) return null;

  const index = data.findIndex(x => isPhoneWithOptionCode(phone, x));

  return index !== -1 ? data[index] : null;
}
export function formatPhoneNumber(phone) {
  const { data } = AuthStore;
  if (!data) return phone;

  const country = getCountryFromPhone(phone, data);
  if (!country) return phone;

  return formatByPattern(phone, country.pattern);
}

export function formatByPattern(phone, pattern) {
  phone = clearPhone(phone);

  let result = '';
  let index = 0;
  for (let i = 0; i < pattern.length && index < phone.length; i++) {
    if (pattern[i] >= '0' && pattern[i] <= '9') {
      result += pattern[i];
      if (phone[index] === pattern[i]) {
        index++;
      }
    } else if (pattern[i] === ' ') {
      result += pattern[i];
    } else if (pattern[i] === 'X') {
      result += phone[index++];
    }
  }

  result += ' ' + phone.substring(index);

  return '+' + result;
}
export function clearPhone(phone) {
  if (!phone) return phone;

  return phone.replace(/ /g, '').replace('+', '').toLowerCase();
}

let data = null;
export async function loadData() {
  if (data) return data;

  try {
    const response = await fetch('/data/countries.txt');
    const text = await response.text();

    const lines = text.split('\n');
    const data2 = [];
    lines.forEach(x => {
      const split = x.split(';');
      const item = {
        prefix: split[0],
        code: split[1],
        name: split[2],
        pattern: split[3],
        count: Number(split[4]),
        emoji: split[5],
      };
      data2.push(item);
    });
    data2.forEach(x => {
      x.phone = '+' + x.prefix;
    });

    data = data2.filter(x => x.emoji);

    return data.reverse();
  } catch (error) {
    console.error(error);
  }
}

export function getCountryFromCode(code, data) {
  if (!code) return null;
  if (!data) return null;

  const index = data.findIndex(x => x.code.toLowerCase() === code.toLowerCase());

  return index !== -1 ? data[index] : null;
}

export function isWhitelistKey(key) {
  if (key >= '0' && key <= '9') return true;
  if (key === ' ') return true;
  if (key === '+') return true;

  return false;
}

export function isValidPhoneNumber(phoneNumber) {
  if (!phoneNumber) return false;

  let isBad = !phoneNumber.match(/^[\d\-+\s]+$/);
  if (!isBad) {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    if (phoneNumber.length < 7) {
      isBad = true;
    }
  }

  return !isBad;
}

export function phoneEquals(phone1, phone2) {
  return clearPhone(phone1) === clearPhone(phone2);
}

export function isValidOption(x, value) {
  if (!x) return false;
  if (!value) return true;

  if (value.length > 0 && value[0] === '(') {
    value = value.substring(1);
  }

  const names = x.name.toLowerCase().split(' ');
  for (let i = 0; i < names.length; i++) {
    if (names[i].length > 0 && names[i][0] === '(') {
      names[i] = names[i].substring(1);
    }
  }
  const phone = clearPhone(x.phone);

  if (names.some(x => x.startsWith(value))) return true;
  if (phone.startsWith(value) || value.startsWith(phone)) return true;

  return false;
}
