import { http, createConfig } from 'wagmi';
import { mainnet, bsc, arbitrum, polygon, avalanche, bscTestnet } from '@wagmi/core/chains';
import { walletConnect, injected, coinbaseWallet } from 'wagmi/connectors';

export const projectId = process.env.REACT_APP_WALLET_ID;

export const metadata = {
  name: 'Reward Program',
  description: 'Reward Program',
  url: 'https://reward.quantum.foundation', // origin must match your domain & subdomain
  icons: ['https://reward.quantum.foundation/img/logo.png'],
};

export const chains = [mainnet, bsc, arbitrum, polygon, avalanche, bscTestnet];

export const wagmiConfig = createConfig({
  chains: chains,
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: http(),
    [arbitrum.id]: http(),
    [polygon.id]: http(),
    [avalanche.id]: http(),
    [bscTestnet.id]: http(),
  },
  connectors: [
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    }),
  ],
});
