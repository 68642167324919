import { useEffect } from 'react';
import { useConnect, useAccount, useBlockNumber } from 'wagmi';

const useInactiveListener = (checkedConnect, connectorName) => {
  const { isConnected } = useAccount();
  const { error } = useBlockNumber();
  const { connectors, connect } = useConnect();

  useEffect(() => {
    const { ethereum } = window;
    if (checkedConnect && ethereum && ethereum.on && !isConnected && !error) {
      const connector = connectors.find(con => con.name === connectorName);
      const handleChainChanged = chainId => {
        connect(connector);
      };

      const handleAccountsChanged = accounts => {
        if (accounts.length > 0) {
          connect(connector);
        }
      };

      const handleNetworkChanged = networkId => {
        connect(connector);
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }
  }, [isConnected, error, checkedConnect, connect, connectorName]);
};

export default useInactiveListener;
