import React, { useEffect, useState } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import FileStore from '../../../Stores/FileStore';
import { getSrc } from '../../../Utils/File';

const FriendListOption = ({ option, checked }) => {
  const minithumbnail = option.profile_photo?.minithumbnail ? 'data:image/jpeg;base64, ' + option.profile_photo?.minithumbnail.data : null;
  const [src, setSrc] = useState(minithumbnail);
  const photoLabel = option.title
    .split(' ', 2)
    .map(w => (w[0] || '').toUpperCase())
    .join('');

  useEffect(() => {
    const src = getSrc(option.profile_photo?.small);
    if (src) {
      setSrc(src);
    } else {
      setSrc(minithumbnail);
    }

    const clientUpdateUserBlob = update => {
      const file = option.profile_photo ? option.profile_photo.small : null;

      const fileId = file ? file.id : -1;

      if (option.id !== update.userId) return;
      if (fileId !== update.fileId) return;

      const src = getSrc(file);
      setSrc(src);
    };

    FileStore.on('clientUpdateUserBlob', clientUpdateUserBlob);
    return () => {
      FileStore.off('clientUpdateUserBlob', clientUpdateUserBlob);
    };
  }, [option.id]);

  return (
    <div className="flex items-center justify-between w-full px-4 my-2">
      <div className="flex items-center">
        <div className="relative w-[32px] h-[32px] overflow-hidden rounded-3xl mr-4 bg-white-2 flex items-center justify-center">
          {photoLabel}
          {src && <img className="absolute top-0 left-0 right-0 bottom-0" src={src} alt={option.title} width={32} height={32} />}
        </div>
        <Typography color="textPrimary">{option.title}</Typography>
      </div>
      <Checkbox color="primary" checked={checked} />
    </div>
  );
};

export default FriendListOption;
