import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import InvitationConfirmLogo from '../../Assets/invitation-confirm.png';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import RewardsIcon from '../../Assets/rewards-icon.png';

const ConfirmInvitations = ({ onNext, groupCount, friendsCount, t }) => {
  const handleNext = useCallback(() => {
    onNext?.();
  }, []);

  return (
    <>
      <div className="auth-caption">
        <Typography variant="body1" color="textPrimary" className="max-w-[594px] text-[21px] font-bold text-center mb-[50px] mt-[20px]">
          An invite for
          <Typography component="span" color="primary" className="text-[21px] font-bold">
            &nbsp;{groupCount}&nbsp;
          </Typography>
          groups has been sent to
          <Typography component="span" color="primary" className="text-[21px] font-bold">
            &nbsp;{friendsCount}&nbsp;
          </Typography>
          of your friends! If they invite their friends, your total potential reward will be
          <Typography component="span" color="primary" className="text-[21px] font-bold">
            &nbsp;${friendsCount * groupCount}&nbsp;
          </Typography>
        </Typography>
        <img src={InvitationConfirmLogo} alt="telegram logo" width={140} height={140} />
      </div>
      <div className="flex justify-center flex-col pt-[50px]">
        <PrimaryButton className="auth-button-full text-8 uppercase mt-5 btn-green flex items-center justify-center h-[85px]" onClick={handleNext}>
          {t('CHECK OUT ADDITIONAL REWARDS')} <img src={RewardsIcon} width={35} height={35} className="ml-5" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default withTranslation()(ConfirmInvitations);
