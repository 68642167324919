const JsonStorage = {
    set(key, value) {
        if (value === undefined) return;
        let stringify = JSON.stringify(value);
        localStorage.setItem(key, stringify);
    },
    get(key, defaultValue = '') {
        let stringify = localStorage.getItem(key);
        if (stringify === null) return defaultValue;
        return JSON.parse(stringify);
    },
    clear() {
        localStorage.clear();
    },
    remove(key) {
        localStorage.removeItem(key);
    },
};

export const WALLET_ADDRESS = '__wallet_address__';
export const WALLET_CONNECTOR = '__wallet_connnector__';

export default JsonStorage;