import React from 'react';

const HowToItWorkStep = ({ step, title, content }) => {
  return (
    <div className="pl-[40px] relative mb-4">
      <div className="text-white-2 font-bold border-2 rounded-[5px] border-white-2 border-solid absolute left-0 w-[30px] h-[30px] flex items-center justify-center text-[18px]">
        {step}
      </div>
      <div className="text-white-4 font-bold leading-[1.8] ">{title}</div>
      <div className="text-white-2 mt-1">{content}</div>
    </div>
  );
};

export default HowToItWorkStep;
