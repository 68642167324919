import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TelegramService from '../../core/services/telegram.service';

const initialState = {
  value: [],
  selectedPublicGroupIds: [],
  selectedUserIds: [],
  listType: 'PublicGroups', // UserList | PublicGroups
  status: 'pending', // pending | fulfilled | rejected
};

export const fetchTelegramGroupAsync = createAsyncThunk(
  'publicGroup/fetchTelegramGroups',
  async () => {
    const response = await TelegramService.getPublicGroups();
    return response.data.data;
  }
);

export const publicGroupSlice = createSlice({
  name: 'publicGroup',
  initialState,
  reducers: {
    setListType: (state, action) => {
      state.listType = action.payload;
    },
    setSelectedPublicGroupIds: (state, action) => {
      state.selectedPublicGroupIds = action.payload;
    },
    setSelectedUserIds: (state, action) => {
      state.selectedUserIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTelegramGroupAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchTelegramGroupAsync.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(fetchTelegramGroupAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      });
  },
});

export const { setListType, setSelectedPublicGroupIds, setSelectedUserIds } = publicGroupSlice.actions;

export const selectPublicGroup = (state) => state.publicGroup.value;
export const selectPublicGroupListType = (state) => state.publicGroup.listType;
export const selectSelectedGroupIds = (state) => state.publicGroup.selectedPublicGroupIds;
export const selectSelectedUserIds = (state) => state.publicGroup.selectedUserIds;

export default publicGroupSlice.reducer;
