import React, { useState, useEffect } from 'react';
import TimePad from '../Pad/TimePad';

const LotteryCard = props => {
  const { image1, image2, name, endDate, handleJoinLotteryPopup } = props;

  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(0);
  const [hour, setHour] = useState(0);
  const [day, setDay] = useState(0);

  useEffect(() => {
    setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = endDate - now;

      // If the count down is finished, write some text
      if (distance < 0) {
        // clearInterval(x);
        setDay(0);
        setHour(0);
        setMin(0);
        setSec(0);
        // setMili(0)
      }

      // Time calculations for days, hours, minutes and seconds
      setDay(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHour(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      setMin(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSec(Math.floor((distance % (1000 * 60)) / 1000));
    }, 1000);
  });

  return (
    <div className="flex flex-col lg:flex-row items-center my-3 text-8 lg:text-[13px] w-full h-full">
      <div
        className={`lg:w-1/5 justify-center lg:justify-start w-full mr-[2px] items-center flex flex-row ${name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'}`}
      >
        <div className="pr-1 relative">
          <div>
            <img src={image1} alt="name" />
          </div>
          <div className="absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2">
            <img src={image2} alt="atom" />
          </div>
        </div>{' '}
        {name}
      </div>
      <div className={`lg:w-1/5 my-2 lg:py-0 lg:px-[2px] lg:justify-start text-center`}>
        <p
          className={` ${name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'} cursor-pointer`}
          onClick={() => {
            navigate('/connect-wallet');
          }}
        >
          Check past result
        </p>
      </div>
      <div
        className={`lg:w-1/5 my-2 lg:py-0 lg:px-[2px] lg:justify-start lg:text-start lg:items-start items-center text-center flex flex-col  w-full justify-between`}
      >
        <p className="text-white-2 mb-1 block lg:hidden">The upcoming prize:</p>
        <span
          className={`my-2 lg:my-0 mx-[2px] ${name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'} `}
        >
          ${name === 'Platinum' ? '50,000' : name === 'Gold' ? '30,000' : name === 'Silver' ? '20,000' : '10,000'} dVoucher
        </span>
      </div>
      <div className="lg:w-1/5 mx-[2px] my-2 lg:my-0 lg:justify-start lg:text-start lg:items-start items-center text-center flex flex-col w-full justify-between">
        <p className="text-white-2 mb-1 block lg:hidden">Lottery start in:</p>
        <div className="text-white-main">
          <TimePad num={(day - (day % 10)) / 10} />
          <TimePad num={day % 10} />:
          <TimePad num={(hour - (hour % 10)) / 10} />
          <TimePad num={hour % 10} />:
          <TimePad num={(min - (min % 10)) / 10} />
          <TimePad num={min % 10} />:
          <TimePad num={(sec - (sec % 10)) / 10} />
          <TimePad num={sec % 10} />
        </div>
      </div>
      <div className="lg:w-1/5 w-full my-2 lg:my-0 ml-[2px] items-center justify-center text-center">
        <button
          onClick={handleJoinLotteryPopup}
          className={`  ${name === 'Platinum' ? 'btn-white' : name === 'Gold' ? 'btn-gold' : name === 'Silver' ? 'btn-silver' : 'btn-bronze'} rounded-[5px] inline-flex items-center justify-center w-52 h-[42px] text-center border-2 border-solid bg-opacity-0 text-white-1 font-bold text-sm uppercase`}
        >
          join {name} lottery
        </button>
      </div>
    </div>
  );
};

export default LotteryCard;
