import React from 'react';
import ReactDOM from 'react-dom/client';
import { ethers } from 'ethers';
import { Web3ReactProvider } from '@web3-react/core';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Mandatory.Plaything.otf';
import './assets/fonts/Montserrat-VariableFont_wght.ttf';
import 'react-toastify/dist/ReactToastify.css';
import TopBanner from './components/AppBar/TopBanner';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { wagmiConfig } from './utils/config';

const queryClient = new QueryClient();

const getLibrary = provider => {
  const library = new ethers.providers.Web3Provider(provider);
  return library;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <TopBanner />
          <App />
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
    <ToastContainer position="top-right" />
  </Web3ReactProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
