import React from 'react';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import HeaderProgress from '../ColumnMiddle/HeaderProgress';
import { cleanProgressStatus, isConnecting } from './Phone';
import AppStore from '../../Stores/ApplicationStore';
import TdLibController from '../../Controllers/TdLibController';
import './Password.css';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import BackButton from '../BackButton';
import { Lock, ReportProblem } from '@material-ui/icons';

class Password extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connecting: isConnecting(AppStore.connectionState),
      password: '',
      showPassword: false,
      info: `Telegram requires verification. The reward program doesn't have access to any of that information`,
      error: '',
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    TdLibController.clientUpdate({
      '@type': 'clientUpdateMonkeyClose',
    });

    AppStore.on('updateConnectionState', this.onUpdateConnectionState);
  }

  componentWillUnmount() {
    AppStore.off('updateConnectionState', this.onUpdateConnectionState);
  }

  onUpdateConnectionState = update => {
    const { state } = update;

    this.setState({ connecting: isConnecting(state) });
  };

  handleNext = () => {
    if (this.password) {
      this.setState({ error: '' });
      this.handleDone();
    } else {
      this.setState({ error: 'Invalid password. Please try again.' });
    }
  };

  handleBack = () => {
    this.props.onChangePhone();
  };

  handleDone = () => {
    const password = this.password;

    this.setState({ loading: true });
    TdLibController.send({
      '@type': 'checkAuthenticationPassword',
      password: password,
    })
      .then(result => {})
      .catch(error => {
        let errorString = null;
        if (error && error['@type'] === 'error' && error.message) {
          errorString = error.message;
        } else {
          errorString = JSON.stringify(error);
        }

        this.setState({ error: errorString }, () => {
          setTimeout(() => this.inputRef.current.focus(), 100);
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });

    this.sendMonkeyPeek(!showPassword);
  };

  sendMonkeyPeek(peek) {
    TdLibController.clientUpdate({
      '@type': 'clientUpdateMonkeyPeek',
      peek,
    });
  }

  handleChange = e => {
    this.password = e.target.value;
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleNext();
    }
  };

  handleBack = () => {
    TdLibController.logOut();
  };

  render() {
    const { passwordHint, t } = this.props;
    const { connecting, loading, error, showPassword, info } = this.state;

    let title = t('EnterPassword');
    if (connecting) {
      title = cleanProgressStatus(t('Connecting'));
    }

    return (
      <form className="auth-root" autoComplete="off">
        <BackButton onClick={this.handleBack} />
        <Typography variant="body1" className="auth-title" color="textPrimary">
          <span>{title}</span>
          {connecting && <HeaderProgress />}
        </Typography>
        <Typography variant="body1" className="auth-subtitle">
          {t('YourAccountProtectedWithPassword')}
        </Typography>

        <FormHelperText error className="mt-[-6px] flex items-center justify-center mb-3 text-[11px]">
          <ReportProblem className="mr-2 text-[16px]" /> DO NOT enter your password here if you are using a public device
        </FormHelperText>

        <FormControl className="auth-input custom-error-text" fullWidth variant="outlined">
          <InputLabel htmlFor="adornment-password" error={Boolean(error)}>
            {t('LoginPassword')}
          </InputLabel>
          <OutlinedInput
            fullWidth
            autoFocus
            autoComplete="off"
            id="adornment-password"
            inputRef={this.inputRef}
            type={showPassword ? 'text' : 'password'}
            disabled={loading}
            error={Boolean(error)}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          {passwordHint && <FormHelperText id="password-hint-text">{passwordHint}</FormHelperText>}
          {(error || info) && (
            <FormHelperText id="password-error-text" error>
              <Lock className="mr-1 text-[16px]" /> {error || info}
            </FormHelperText>
          )}
        </FormControl>
        <div className="text-center">
          <PrimaryButton className={`${loading ? 'btn-disabled' : 'btn-gold'} auth-button text-8 uppercase mt-5`} onClick={this.handleNext}>
            {t('Next')}
          </PrimaryButton>
        </div>
      </form>
    );
  }
}

export default withTranslation()(Password);
