import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_DVOUCHER_REWARD_SYSTEM_API_BASE_URL;

const headers = {
  'ngrok-skip-browser-warning': 'true', // Set the ngrok-skip-browser-warning header
  'User-Agent': 'Custom User Agent', // Set a custom User-Agent header
  'Content-Type': 'application/json',
};

/** GET */
// GET - all supported blockchains data
export const fetchBlockchains = async () => {
  const response = await fetch(`${API_BASE_URL}/blockchains`, { headers });
  if (!response.ok) {
    throw new Error(`Failed to fetch blockchains: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

// GET - all supported blockchains data
export const getActivePersonalRewardGiveaway = async () => {
  const response = await fetch(`${API_BASE_URL}/sweep-widget/personal-reward`, { headers });
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(`No active personal reward giveaway url: ${response.status}`);
    } else {
      throw new Error(`Failed to fetch active personal reward giveaway url: ${response.status}`);
    }
  }
  const data = await response.json();
  return data;
};

/** POST */
// POST - create a user based on wallet address
export const registerUserWallet = async account => {
  return await fetch(`${API_BASE_URL}/user/create`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      wallet: account,
    }),
  })
    .then(response => response.json())
    .catch(error => console.error(error));
};

export const getDouchersOfWallet = async (account, nominal = null) => {
  const params = new URLSearchParams({
    wallet: account,
  });

  if (nominal) {
    params.set('nominal', nominal);
  }

  const url = `${API_BASE_URL}/user/dvouchers?${params.toString()}`;

  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(`Failed to fetch douchers: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const userSentTgInvitations = async (wallet, invitor_id, group_id, userIds) => {
  const url = `${API_BASE_URL}/rewards/userSentTgInvitations`;
  return axios.post(url, { wallet, invitor_id, group_id, userIds }, { headers }).then(res => {
    console.log('userSentTgInvitations', res);
  });
};

export const getRewardPoints = async wallet => {
  const url = `${API_BASE_URL}/rewards/getTotalPoints`;
  return await axios.post(url, { wallet }, { headers });
};
