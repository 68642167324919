import React from "react";

const PrimaryButton = ({ children, className = "", ...others }) => {
    return (
        <button type="button" className={`header-btn border-2 border-solid border-white-2 bg-opacity-0 text-white-2 font-bold font-montserrat ${className}`} {...others}>
            {children}
        </button>
    );
}

export default PrimaryButton;