import React, { Component } from 'react';
import { compose } from './Utils/HOC';
import withLanguage from './Language';
import withTelegramTheme from './Theme';
import { withTranslation } from 'react-i18next';
import AuthForm from './Components/Auth/AuthForm';
import { isMobile } from './Utils/Common';
import KeyboardManager from './Components/Additional/KeyboardManager';
import { OPTIMIZATIONS_FIRST_START } from './Constants';
import ApplicationStore from './Stores/ApplicationStore';
import AuthorizationStore from './Stores/AuthorizationStore';
import MessageStore from './Stores/MessageStore';
import CacheStore from './Stores/CacheStore';
import FileStore from './Stores/FileStore';
import TdLibController from './Controllers/TdLibController';
import InvitationPage from './Components/InvitationPage';
import './TelegramApp.css';

class TelegramInvitationApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevAuthorizationState: AuthorizationStore.current,
      authorizationState: null,
      tdlibDatabaseExists: false,
      inactive: false,
      fatalError: false,
      nativeMobile: isMobile(),
      isSmall: window.innerWidth < 800,
    };

    this.replyMessageId = 0;
    this.editMessageId = 0;
    this.keyMap = new Map();

    document.addEventListener('keyup', event => {
      this.keyMap.delete(event.key);
    });

    TdLibController.init();
  }

  componentDidMount() {
    TdLibController.on('update', this.onUpdate);

    ApplicationStore.on('clientUpdateFocusWindow', this.onClientUpdateFocusWindow);
    ApplicationStore.on('clientUpdateTdLibDatabaseExists', this.onClientUpdateTdLibDatabaseExists);
    ApplicationStore.on('updateAuthorizationState', this.onUpdateAuthorizationState);
    ApplicationStore.on('updateFatalError', this.onUpdateFatalError);
    MessageStore.on('clientUpdateReply', this.onClientUpdateReply);
    ApplicationStore.on('clientUpdateSetPhone', this.onClientUpdateSetPhone);
    KeyboardManager.add(this.keyboardHandler);
    CacheStore.contacts;
    FileStore.getStore();
  }

  componentWillUnmount() {
    TdLibController.off('update', this.onUpdate);

    ApplicationStore.off('clientUpdateFocusWindow', this.onClientUpdateFocusWindow);
    ApplicationStore.off('clientUpdateTdLibDatabaseExists', this.onClientUpdateTdLibDatabaseExists);
    ApplicationStore.off('updateAuthorizationState', this.onUpdateAuthorizationState);
    ApplicationStore.off('updateFatalError', this.onUpdateFatalError);
    MessageStore.off('clientUpdateReply', this.onClientUpdateReply);
    ApplicationStore.off('clientUpdateSetPhone', this.onClientUpdateSetPhone);
    KeyboardManager.remove(this.keyboardHandler);
  }

  onClientUpdateReply = update => {
    const { messageId } = update;

    this.replyMessageId = messageId;
  };

  onClientUpdateFocusWindow = update => {
    this.keyMap.clear();
  };

  onClientUpdateTdLibDatabaseExists = update => {
    const { exists } = update;

    if (!exists) {
      this.setState({
        authorizationState: {
          '@type': 'authorizationStateWaitTdlib',
        },
        tdlibDatabaseExists: exists,
      });
    }
  };

  onUpdate = update => {
    if (OPTIMIZATIONS_FIRST_START) {
      if (!this.checkServiceWorker) {
        this.checkServiceWorker = true;
      }
    }
  };

  onUpdateFatalError = update => {
    this.setState({ fatalError: true });
  };

  onUpdateAuthorizationState = update => {
    const { authorization_state: authorizationState } = update;
    let { prevAuthorizationState } = this.state;

    if (
      authorizationState &&
      (authorizationState['@type'] === 'authorizationStateLoggingOut' || authorizationState['@type'] === 'authorizationStateClosed')
    ) {
      prevAuthorizationState = null;
    }

    this.setState({
      authorizationState,
      prevAuthorizationState,
    });

    if (!window.hasFocus) return;
    if (!authorizationState) return;

    TdLibController.send({
      '@type': 'setOption',
      name: 'online',
      value: { '@type': 'optionValueBoolean', value: true },
    });
  };

  onClientUpdateSetPhone = () => {
    this.setState({
      changePhone: false,
    });
  };

  handleChangePhone = () => {
    this.setState({
      changePhone: true,
    });
  };

  handleRequestQRCode = () => {
    const { changePhone, authorizationState } = this.state;

    if (changePhone && authorizationState && authorizationState['@type'] === 'authorizationStateWaitOtherDeviceConfirmation') {
      this.setState({ changePhone: false });
    } else {
      TdLibController.send({
        '@type': 'requestQrCodeAuthentication',
        other_user_ids: [],
      });
    }
  };

  handleDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleRefresh = () => {
    this.setState({ fatalError: false });
    window.location.reload();
  };

  handleDestroy = () => {
    this.setState({ fatalError: false });
    TdLibController.send({ '@type': 'destroy' });
  };

  render() {
    const { t, theme, account } = this.props;
    const { inactive, nativeMobile, fatalError } = this.state;
    let { authorizationState: state, prevAuthorizationState, changePhone } = this.state;
    if (changePhone) {
      state = { '@type': 'authorizationStateWaitPhoneNumber' };
    } else if (
      !state ||
      state['@type'] === 'authorizationStateClosed' ||
      state['@type'] === 'authorizationStateWaitEncryptionKey' ||
      state['@type'] === 'authorizationStateWaitTdlibParameters'
    ) {
      if (prevAuthorizationState) {
        state = prevAuthorizationState;
      } else {
        state = { '@type': 'authorizationStateWaitPhoneNumber' };
      }
    }

    let page = <InvitationPage authorizationState={state} />;

    if (inactive) {
      // page = <InactivePage />;
      page = <div>InactivePage</div>;
    } else if (state) {
      switch (state['@type']) {
        case 'authorizationStateClosed':
        case 'authorizationStateClosing':
        case 'authorizationStateLoggingOut':
        case 'authorizationStateReady': {
          break;
        }
        case 'authorizationStateWaitOtherDeviceConfirmation':
        case 'authorizationStateWaitCode':
        case 'authorizationStateWaitRegistration':
        case 'authorizationStateWaitPassword':
        case 'authorizationStateWaitPhoneNumber':
        case 'authorizationStateWaitTdlib':
          page = <AuthForm authorizationState={state} onChangePhone={this.handleChangePhone} onRequestQRCode={this.handleRequestQRCode} />;
          break;
        case 'authorizationStateWaitEncryptionKey':
        case 'authorizationStateWaitTdlibParameters': {
          break;
        }
      }
    }

    return (
      <div id="app" className="dark telegram-app" onDragOver={this.handleDragOver} onDrop={this.handleDrop}>
        {page}
      </div>
    );
  }
}

const enhance = compose(withLanguage, withTranslation(), withTelegramTheme);

export default enhance(TelegramInvitationApp);
