import http from '../http/telegram.http';

class TelegramService {
    /**
     * 
     * @description post nft to the telegram group.
     * @returns 
     */
    postNFT2Twitter({ chain, tokenAddress, tokenId, affiliateAddress }) {
        return http.post(`/api/nfts/telegram-group`, { affiliateAddress, chain, tokenAddress, tokenId });
    }
    getPublicGroups() {
        return http.get(`/api/nfts/telegram/public-groups`);
    }
    /**
     * 
     * @param { Number } chatId 
     * @param { Array<Number | String> } userIds 
     * @returns 
     */
    checkChatMembers(chatId, userIds) {
        return http.post(`/api/telegram/check-chat-members`, { groupId: chatId, userIds });
    }
}

export default new TelegramService();
