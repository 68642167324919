import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { InputAdornment, Link, TextField, Typography } from '@material-ui/core';

import TelegramLogo from '../../Assets/Icons/TelegramLogo';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import { fetchTelegramGroupAsync } from '../../../../store/reducers/publicGroupSlice';
import GroupListOption from './components/GroupListOption';
import HeaderProgress from '../ColumnMiddle/HeaderProgress';

const SelectGroups = ({ t, onNext }) => {
  const disptach = useDispatch();
  const publicGroups = useSelector(state => state.publicGroup.value);
  const groupLength = publicGroups?.length || 0;

  const [selectGroups, setSelectedGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(() => {
    onNext(selectGroups);
  }, [selectGroups]);

  const handleFilterOptions = useCallback((options, { inputValue }) => {
    if (!options) return;

    return options.filter(({ title }) => title.toLowerCase().includes(inputValue.toLowerCase()));
  }, []);

  const handleGroupChange = useCallback((event, values) => {
    setSelectedGroups(values);
  }, []);

  const toggleSelectAll = useCallback(() => {
    if (selectGroups.length === publicGroups.length) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups([...publicGroups]);
    }
  }, [publicGroups, selectGroups.length]);

  useEffect(() => {
    if (!publicGroups || publicGroups.length === 0) {
      setLoading(true);
      disptach(fetchTelegramGroupAsync()).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (publicGroups.length > 0) {
      setSelectedGroups(publicGroups);
    }
  }, [publicGroups]);

  const nextable = !loading && selectGroups.length > 0;

  return (
    <>
      <div className="auth-caption">
        <Typography variant="body1" color="textPrimary" className="reward-caption-text max-w-[453px] mb-4">
          Get rewards by inviting your friends to our group
        </Typography>
        <TelegramLogo />
      </div>
      <div className="flex justify-center flex-col pt-3">
        <Typography variant="body1" className="auth-title">
          {loading && <HeaderProgress />}
        </Typography>
        <div className="text-center mt-4 mb-7">
          <Typography variant="body1" className="auth-subtitle" component="span">
            Inviting your friends to all the
          </Typography>
          <Typography component="span" color="primary">
            &nbsp;{groupLength}&nbsp;
          </Typography>
          <Typography variant="body1" className="auth-subtitle" component="span">
            groups will boost your reward by
          </Typography>
          <Typography component="span" color="primary">
            &nbsp;{groupLength * 100}%
          </Typography>
        </div>
        <Autocomplete
          debug={false}
          id="country-select"
          multiple
          noOptionsText={t('NoResult')}
          options={publicGroups || []}
          disabled={loading}
          autoHighlight
          disableCloseOnSelect
          getOptionLabel={option => (option ? option.title || '' : [])}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={option => <GroupListOption option={option} checked={!!selectGroups.find(({ id }) => option.id === id)} />}
          renderInput={params => (
            <TextField
              classes={{ root: 'auth-input remove-selected' }}
              {...params}
              placeholder="Search groups"
              label={t('Group list')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    {selectGroups.length ? <Typography className="text-white-4 pl-1">{selectGroups.length} groups selected</Typography> : ''}
                    <Link className="absolute top-[-26px] right-0" component="button" color="primary" variant="body2" onClick={toggleSelectAll}>
                      {groupLength && selectGroups.length === groupLength ? 'Unselect All' : 'Select All'}
                    </Link>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          filterOptions={handleFilterOptions}
          value={selectGroups}
          onChange={handleGroupChange}
        />
        <div className="text-center">
          <PrimaryButton className={`${!nextable ? 'btn-disabled' : 'btn-gold'} auth-button text-8 uppercase mt-5`} onClick={handleNext}>
            {t('Next')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SelectGroups);
