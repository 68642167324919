import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const JoinLotteryCard = props => {
  const { image, name, tickets } = props;
  const [dVoucher, setDVoucher] = useState(0);

  const handleStyle = {
    border: 0,
    height: 15,
    width: 15,
    marginTop: -5,
    boxShadow:
      name === 'Mega'
        ? '0 0 12px rgba(255, 255, 255)'
        : name === 'Platinum'
          ? '0 0 12px rgba(255, 255, 255)'
          : name === 'Gold'
            ? '0 0 16px rgba(233, 161, 52)'
            : name === 'Silver'
              ? '0 0 12px rgba(152, 152, 152)'
              : '0 0 12px rgba(222, 166, 149)',
    backgroundColor:
      name === 'Mega' ? '#fff' : name === 'Platinum' ? '#fff' : name === 'Gold' ? '#e9a134' : name === 'Silver' ? '#989898' : '#dea695',
  };

  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-start text-start items-center text-8 md:text-[14px] w-full h-full px-7 lg:px-0">
      <div
        className={`w-full md:w-1/6 mb-2 md:mb-0 text-2 md:text-[14px] justify-center md:justify-start text-start items-center flex flex-row ${name === 'Mega' ? 'text-white-main' : name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'}`}
      >
        <img src={image} alt={name} className="w-12 md:w-8 mr-3" />
        {name}
      </div>
      <div className="flex flex-col md:items-start items-center justify-center md:justify-start w-full md:w-1/6 mb-2 md:mb-0">
        <p className="text-white-2 md:hidden border-solid border-b-[1px] border-white-6 py-[3px] px-7 text-center text-base">Wining Prize</p>
        <div
          className={`items-center md:my-0 lg:my-0 mx-[2px] ${name === 'Mega' ? 'text-white-main' : name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'} `}
        >
          ${name === 'Mega' ? '100,000' : name === 'Platinum' ? '50,000' : name === 'Gold' ? '30,000' : name === 'Silver' ? '20,000' : '10,000'}
        </div>
      </div>
      <div className="flex flex-col md:items-start items-center justify-center md:justify-start w-full md:w-1/6 mb-2 md:mb-0">
        <p className="text-white-2 md:hidden border-solid border-b-[1px] border-white-6 pb-[3px] px-2 text-center">Your dVoucher lottery tickets</p>
        <div className={`items-center `}>{tickets}</div>
      </div>
      <div className="flex flex-col md:items-start items-center justify-center md:justify-start w-full md:w-1/2 mb-2 md:mb-0">
        <p className="text-white-2 md:hidden border-solid border-b-[1px] border-white-6 pb-[3px] px-2 text-center">How many dVoucher to use?</p>
        <div className={`flex flex-row justify-center md:justify-start w-full md:my-0 my-2`}>
          <div className="flex flex-col w-full items-center justify-center mt-3">
            <Slider
              value={dVoucher}
              min={0}
              max={100}
              defaultValue={0}
              onChange={value => {
                setDVoucher(value);
              }}
              trackStyle={{ backgroundColor: 'transparent', height: 10 }}
              handleStyle={handleStyle}
              disabled={tickets === 0 ? true : false}
            />
            <div
              className={`flex flex-row justify-between text-[12px] text-white-main items-center w-full mt-2 font-bold ${name === 'Mega' ? 'text-white-main' : name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'}`}
            >
              <span>0%</span>
              <span>100%</span>
            </div>
          </div>
          <div className="justify-center items-center flex flex-row px-2 ml-3 border-2 border-solid border-white-2 bg-transparent bg-opacity-0 text-white-1 font-bold text-base rounded-[5px] w-16 h-12">
            <input
              onChange={e => setDVoucher(e.target.value)}
              type="text"
              value={`${dVoucher}`}
              className={`h-12 w-7 bg-transparent ${name === 'Mega' ? 'text-white-main' : name === 'Platinum' ? 'text-white-main' : name === 'Gold' ? 'text-yellow-main' : name === 'Silver' ? 'text-white-2' : 'text-[#dea695]'}`}
            />
            %
          </div>
        </div>
      </div>
      <div className="my-3 md:my-0 w-full md:w-1/3 items-center text-center flex flex-col justify-center mx-1">
        <a
          href=""
          target="_blank"
          className={`${name === 'Mega' ? 'btn-white' : name === 'Platinum' ? 'btn-white' : name === 'Gold' ? 'btn-gold' : name === 'Silver' ? 'btn-silver' : 'btn-bronze'} rounded-[5px] inline-flex items-center justify-center w-52 h-[42px] text-center border-2 border-solid bg-opacity-0 text-white-1 font-bold text-sm uppercase`}
        >
          join {name} lottery
        </a>
      </div>
    </div>
  );
};

export default JoinLotteryCard;
