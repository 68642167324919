import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import HowToItWorkStep from './components/HowToItWorkStep';
import TelegramApp from '../../components/Telegram/TelegramApp';
import { useCustomWeb3 } from '../../utils/web3/Web3ManageProvider';

const howToItWorks = [
  {
    title: 'One-Click Invites',
    content: 'Utilize our built-in interface to invite all your friends to a Telegram group effortlessly with just one click!',
  },
  {
    title: 'Instant Notification',
    content: `As soon as you add friends to the group, they'll receive a personalized message explaining why they were added. This message also encourages them to join the fun and invite their own friends to the group, ensuring community growth and that everyone is rewarded for their efforts.`,
  },
  {
    title: 'Earn for Every Invite',
    content: `For each friend that joins the group through your invitation, you'll earn a $1 dVoucher.`,
  },
  {
    title: 'Proof of Humanity',
    content:
      'To qualify for each $1 dVoucher, the friends you invite must also prove they are real humans by inviting their own friends to the group. This step ensures that our community grows with real and engaged members.',
  },
  {
    title: 'Reward Upon Validation',
    content: `Once your invited friend successfully adds another friend to the group, thus confirming  their human identity, you'll be eligible to receive your $1 dVoucher for that friend. This system was created to deter bot activity, and to ensure that each new group member contributes positively to the community's growth.`,
  },
  {
    title: 'Maximize Your Reward',
    content: `There’s no cap on how many dVouchers you can earn. If you invite 100 friends, and each of them invites at least one friend, you'll earn $100 in dVouchers (100 x $1 = $100). These rewards are uncapped, encouraging  you and your network to contribute to the expanding growth of the Telegram community.`,
  },
  {
    title: 'Multi-Group Invitations',
    content: `Instead of inviting all of your friends to just one group, you now have the option to invite them to multiple available groups on Telegram with the same one-click convenience. This means you can introduce your friends to a variety of communities within the Telegram ecosystem, all at once, earning you rewards with ease.`,
  },
  {
    title: 'Multiply Your dVouchers',
    content: `For every group your friend joins through your invitation, you’ll earn separate $1 dVouchers, effectively multiplying your rewards. If you invite 100 friends to 10 different groups, and they prove their humanity by inviting another friend to each of those groups, you could earn up to $1,000 from a single click!`,
  },
  {
    title: 'Enjoy Your dVouchers',
    content:
      'Claim your earned dVouchers and convert them into any token within the Quantum Foundation ecosystem. This feature provides you with the flexibility to choose how you want to invest or spend your rewards across a diverse ecosystem that ecompasses a vast range of tokens.',
  },
];

const ReferralProgram = () => {
  const { account } = useCustomWeb3();
  return (
    <>
      <Container>
        <BreadCrumbs>
          <BreadLinks href="/">Main page</BreadLinks>
          <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
          <BreadLinks href="/rewards/referral-program">Referral program</BreadLinks>
        </BreadCrumbs>

        <StepSec>
          <div className="">
            <div className="font-play text-white-main text-8 md:text-1 text-center wow fadeInUp" data-wow-delay="0.1s">
              Telegram's Referral program
            </div>
            <div className="text-center font-montserrat mt-2 wow fadeInUp" data-wow-delay="0.3s">
              <p className="text-white-1 text-[24px] mt-7 leading-8">Earn a $1 dVoucher Every Time You Invite a Friend to a Group!</p>
              <p className="text-white-2 text-8 mt-7 leading-8">
                We're excited to launch our revamped referral program that makes inviting friends to Telegram groups more rewarding than ever. <br />
                With our new system, you and your friends can now earn dVouchers simply by spreading the word about us and encouraging your friends to
                join our Telegram groups.
              </p>
            </div>
            <div className="flex mt-5 justify-center">
              <div className="max-w-2xl w-full mb-2 min-h-[60vh]">
                <TelegramApp account={account} />
              </div>
            </div>
            <div>
              <div className="text-white-main font-bolder text-[30px] uppercase mb-4">Here's How It Works:</div>
              {howToItWorks.map(({ title, content }, index) => (
                <HowToItWorkStep key={index} step={index + 1} title={title} content={content} />
              ))}
            </div>
          </div>
        </StepSec>
      </Container>
    </>
  );
};

export default ReferralProgram;

const Container = styled.div`
  width: 1360px;
  margin: 0px auto;
  max-width: 100%;
  padding: 0 20px;
`;
const BreadCrumbs = styled.div`
  color: #989898;
  margin-bottom: 50px;
  svg {
    margin-right: 11px;
  }
`;
const BreadLinks = styled.a`
  margin-right: 11px;
  color: #989898;
  &:after {
    content: '';
  }
  &:last-child {
    color: #e9a134;
  }
`;
const StepSec = styled.section`
  margin-top: 88px;
`;
