import React from 'react'
import MainLayout from '../../components/layout/MainLayout';
import TelegramApp from '../../components/Telegram/TelegramApp';

function InviteFriends2Telegram() {

  return (
    <MainLayout>
        <h2 className='text-center text-white-main font-montserrat font-bold text-2 mt-14'>*Get rewards by inviting your friends to our group*.</h2>
        <div className='mt-5 flex justify-center'>
          <div className='max-w-2xl w-full mb-2 min-h-[60vh]'>
            <TelegramApp />
          </div>
        </div>
    </MainLayout>
  )
}

export default InviteFriends2Telegram
