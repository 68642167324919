import React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const BackButton = ({ onClick }) => {
  return (
    <div className="absolute top-[15px] left-[34px]">
      <IconButton title="back" onClick={onClick}>
        <ArrowBack />
      </IconButton>
    </div>
  );
};

export default BackButton;
